/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.main-widget-panel {
  .mat-dialog-container {
    padding: 0;
    border-radius: 10px;
  }
}

.mat-option {
  font-family: "Open Sans", sans-serif;
}
